import mouse from './mouse.png';
import './App.css';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p className="head">
          Lefty Legion
        </p>
        <p>
          L50 Mouse
        </p>
        <img src={mouse} className="App-logo" alt="display of the L50 mouse" />
        <a href="/L50 Hub.exe" download>
          Download Mouse Customization Software (Windows/Linux only)
        </a>
        <div className="desc">changes DPI settings, button actions, and more</div>
        <br/>
        <div>We're sorry, mouse customization is not supported on Macs</div>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
      <Footer />
    </div>
  );
}

export default App;
